.modal-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-weight: 600;
    font-size: 25px;
    margin-top: 0;
    padding-bottom: 15px;
  }

  .modal-header__close-icon:hover {
    cursor: pointer;
  }
}
