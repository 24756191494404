.report {
  display: flex;
  width: 100%;
  justify-content: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

  .report__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background-color: rgb(255, 255, 255);
    height: 100px;
    margin-bottom: 20px;
    border-radius: 15px;
    font-weight: 500;
    font-size: 20px;
    padding-left: 30px;
    padding-right: 30px;

    @media only screen and (max-width: 1400px) {
      height: 60px;
      margin-bottom: 10px;
      font-size: 16px;
    }
    .report__status {
      display: flex;
      min-width: fit-content;
      width: 20%;

      .report__status--passed {
        margin-right: 30px;
        border-radius: 16px;
        padding: 2px 10px;
        font-weight: 800;
        font-size: 20px;
        background-color: #ffd0d0;
        border: solid 2px #fb8176;
        color: #fb8176;
      }

      .report__status--pending {
        margin-right: 30px;
        border-radius: 16px;
        padding: 2px 10px;
        font-weight: 800;
        font-size: 20px;
        background-color: #fef4d9;
        border: solid 2px #e9a65d;
        color: #e9a65d;
      }

      .report__status--signed {
        margin-right: 30px;
        border-radius: 16px;
        padding: 2px 10px;
        font-weight: 800;
        font-size: 20px;
        background-color: #f1f4f2;
        border: solid 2px #719882;
        color: #86b099;
      }
    }

    .report__email {
      min-width: fit-content;
      width: 25%;
    }

    .report__month {
      color: #719882;
      width: 18%;
    }

    .report__hours {
      min-width: fit-content;
      color: #3e50f2;
      font-weight: 700;
      width: 8%;
    }

    .report__deadline--overdue {
      display: flex;
      justify-content: end;
      color: rgb(192, 71, 81);
      width: 20%;
      margin-right: 20px;
    }

    .report__deadline--pending {
      display: flex;
      justify-content: end;
      color: #719882;
      width: 20%;
      margin-right: 20px;
    }

    .report__signed-datetime {
      display: flex;
      justify-content: end;
      width: 20%;
      margin-right: 20px;
    }

    .report__edit-icon {
      width: 2%;
    }

    .report__delete-icon {
      padding-left: 5px;
      width: 2%;
    }
  }
}
