.workers-management__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 75px;
  padding: 0 30px 0 30px;
}

.workers-management__content {
  padding: 30px;

  .workers-management__list-of-users {
    margin-top: 20px;
  }
}
