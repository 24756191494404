.sign-hours {
  padding: 30px;

  .sign-hours__expired-text {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  .sign-hours__modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1000;
    border-radius: 7px;
    height: 520px;
    width: 560px;
    padding: 35px;
  }

  @media only screen and (max-width: 600px) {
    .sign-hours__modal {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      z-index: 1000;
      height: 400px;
      width: 90%;
    }
  }

  .sign-hours__success {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1000;
    border-radius: 7px;
    height: 260px;
    width: 600px;
    @media only screen and (max-width: 600px) {
      width: 90%;
      height: 300px;
    }
    padding: 35px;
  }
}
