.create-report-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
  border-radius: 7px;
  height: 500px;
  width: 500px;
  padding: 35px;

  .create-report-modal__container {
    display: flex;
    flex-direction: column;
    row-gap: 75px;
    margin-top: 20px;
  }

  .create-report-modal__pickers {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  @media only screen and (max-width: 1200px) {
    width: 30%;
    .create-report-modal__container {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      margin-top: 20px;
    }
    .create-report-modal__pickers {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
    }
  }
}
