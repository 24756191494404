.user-tile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 19px 16px 18px;
  @media only screen and (min-width: 1440px) {
    padding: 25px 16px 22px;
  }
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-bottom: 12px;

  .user-tile__edit-input {
    font-size: 20px;
    @media only screen and (max-width: 1400px) {
      font-size: 16px;
    }
    border-radius: 6px;
    border: 2px solid rgb(198, 198, 245);
    padding: 0;
    box-sizing: border-box;
    width: 100%;
  }

  .user-tile__field {
    border: 2px solid transparent;
    border-radius: 6px;
    padding: 0;
  }

  .user-tile__delete-icon:hover {
    cursor: pointer;
  }

  .user-tile__save-user:hover {
    cursor: pointer;
  }

  .user-tile__edit-user:hover {
    cursor: pointer;
  }
}
