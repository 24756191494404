.create-user-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
  border-radius: 7px;
  height: 500px;
  width: 500px;
  padding: 35px;

  .create-user-modal__container {
    display: flex;
    flex-direction: column;
    row-gap: 75px;
    margin-top: 20px;
  }

  p {
    font-weight: 600;
    font-size: 25px;
    margin-top: 0;
    padding-bottom: 15px;
  }
}
