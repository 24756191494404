.searchbar {
  min-width: 100px;
  width: 500px;
  padding-right: 30px;

  .searchbar__border {
    border-color: blue;
    border-width: 46px;
  }
}
