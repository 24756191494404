.header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header__title-and-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
    .header__buttons {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      min-width: 260px;

      .header__button {
        height: 45px;
        width: 250px;
        min-width: 120px;
        margin-left: 25px;
      }
    }
  }
}
