.navigation {
  display: flex;
  align-items: center;
  font-size: 22px;
  height: 75px;
  background-color: white;
  padding: 0 30px 0 30px;
  justify-content: space-between;

  .navigation__user {
    display: flex;
    justify-content: flex-end;
    width: 20%;
  }
}
