.user {
  display: flex;
  align-items: center;

  .user__text {
    color: black;
    text-transform: none;
    font-weight: 500;
  }

  .user__avatar {
    outline: 2px solid black;
    background: rgb(0, 0, 0);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    z-index: 999;
  }
}
