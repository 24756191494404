.filters-and-sorting {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .MuiTextField-root {
    width: 100% !important;
  }
}
