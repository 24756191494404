.sign-hours-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 30px;
  font-size: 20px;
  @media only screen and (max-width: 600px) {
    font-size: 18px;
    padding-bottom: 15px;
  }

  .sign-hours-modal__deadline {
    display: flex;
    flex-direction: row;
  }

  .sign-hours-modal__deadline--overdue {
    color: red;
    margin-left: 5px;
  }

  .sign-hours-modal__deadline--pending {
    color: green;
    margin-left: 5px;
  }

  .sign-hours-modal__text--blue {
    color: blue;
    margin-left: 5px;
  }
}

.sign-hours-modal-bottom {
  display: flex;
  flex-direction: column;

  .sign-hours-modal-bottom__comment {
    display: flex;
    flex-direction: column;
    height: 150px;
    @media only screen and (max-width: 600px) {
      height: 130px;
    }
  }

  .sign-hours-modal-bottom__input-comment {
    background-color: white;
    border: none;
    font-size: 20px;
    @media only screen and (max-width: 600px) {
      font-size: 18px;
    }
    align-self: flex-start;
    padding-left: 0;
    font-weight: 500;
    padding-bottom: 10px;
    color: #000000;
  }

  .sign-hours-modal-bottom__approve {
    display: flex;
    justify-content: space-between;
  }

  textarea {
    width: 100%;
    height: 100px;
    padding: 12px 20px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
    font-size: 16px;
    resize: none;
    margin-bottom: 20px;
  }
}
