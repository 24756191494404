.success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 20px;
  @media only screen and (max-width: 600px) {
    font-size: 18px;
  }

  .success__text {
    text-align: center;
  }

  .success__text--bold {
    font-weight: bold;
    margin-bottom: 28px;
  }

  .success__number-text {
    @media only screen and (max-width: 600px) {
      margin-left: 30px;
      margin-right: 30px;
      text-align: center;
    }
    .success__text--blue {
      color: #3e50f2;
    }
  }

  .success__button {
    margin-top: 100px;
  }

  .success__time {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
      align-items: center;
    }

    .success__time-text {
      margin-left: 0.1rem;
      color: black;
    }

    .success__time--negative {
      color: red;
      margin-left: 5px;
      @media only screen and (max-width: 600px) {
        margin-left: 5px;
      }
    }

    .success__time--positive {
      color: green;
      margin-left: 5px;
      @media only screen and (max-width: 600px) {
        margin-left: 5px;
      }
    }
  }
}
