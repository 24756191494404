body {
  background-color: #fcfcff;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  cursor: pointer;
}

h3 {
  font-family: Arial, Helvetica, sans-serif;
  color: #3E50F2;
  white-space: nowrap;
}
