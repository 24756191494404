.generate-report-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 20%;
    overflow: auto;
    width: 15%;
    max-height: 35rem;
    transform: translate(-50%, -50%);
    padding: 2rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 0.5rem;
  
    @media only screen and (max-width: 1600px) {
      height: 50%;
      width: 20%;
    }
  
    @media only screen and (max-width: 1200px) {
      width: 30%;
    }

    .generate-report-modal__content {
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      flex-direction: column;
      margin-top: 20px;
    }

    h2 {
      margin-top: 0;
      text-align: center;
    }
}

ul {
  margin: 8px;
  padding: 0;
}