.admin {
  padding: 30px;

  .admin__spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
  }
}
