.logo__image {
  cursor: pointer;
}

.logo__text {
  font-size: 20px;
  font-weight: 700;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.1em;
  color: #3e50f2;
  padding-left: 15px;
  cursor: pointer;
}
